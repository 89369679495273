import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; // Import the Link component from react-router-dom
import { getFirestore, collection, getDoc, setDoc, doc, query, onSnapshot, orderBy } from "firebase/firestore";

import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Forms.css';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);

const Episodes = () => {
  const [season, setSeason] = useState(null);
  const [episodes, setEpisodes] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const seriesKey = params.get('seriesKey');
  const seriesTitle = params.get('seriesTitle');
  const seasonKey = params.get('seasonKey');
  const seasonTitle = params.get('seasonTitle');

  useEffect(() => {
    fetchSeasonDetails();
  }, [seasonKey]);

  useEffect(() => {
    // Create a reference to the episodes collection
    const episodesCollection = collection(db, 'episodes');

    // Create a query to sort episodes by title
    const q = query(episodesCollection, orderBy("title"));

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, snapshot => {
      const episodeList = snapshot.docs.map(doc => ({
        id: doc.id,
        key: doc.data().key,
        title: doc.data().title,
        posterUrl: doc.data().posterUrl
      }));

      setEpisodes(episodeList);
    });

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  const fetchSeasonDetails = async () => {
    if (!seasonKey) return;

    const seasonRef = doc(db, 'season', seasonKey);
    const seasonSnap = await getDoc(seasonRef);
    if (seasonSnap.exists) {
      setSeason(seasonSnap.data());
    }
  };

  // Function to handle button click
  const handleAddEpisodeClick = () => {
    window.location.href = '/episodedetails?mode=add&seriesKey=' + seriesKey + '&seriesTitle=' + seriesTitle + '&seasonKey=' + seasonKey + '&seasonTitle=' + seasonTitle;
  };

  return (
    <div className="form-container">
      <div className="breadcrumbs">
        /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
        /&nbsp; <Link to={`/series`}>Series</Link>&nbsp;
        /&nbsp; <Link to={`/seriesdetails?mode=edit&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>{seriesTitle && seriesTitle}</Link>&nbsp;
        /&nbsp; <Link to={`/seasons?seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>Seasons</Link>&nbsp;
        /&nbsp; <Link to={`/seasondetails?mode=edit&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}&seasonKey=${seasonKey}&seasonTitle=${seasonTitle}`}>{seasonTitle && seasonTitle}&nbsp;</Link>&nbsp;
        /&nbsp; Episodes
      </div>
      <h1 className="form-title">
        {seriesTitle}&nbsp;-&nbsp;{seasonTitle}&nbsp;
        Episodes
      </h1>
      <button type="submit" className="form-button" onClick={handleAddEpisodeClick}>Add a Episode</button>

      <br />
      <br />
      
      {/* Loop through the episodes array and create a list item and a link for each */}
      {episodes.map(episode => (
        <li key={episode.id}>
          <Link to={`/episodedetails?episodeKey=${episode.key}&seasonKey=${seasonKey}&seasonTitle=${seasonTitle}&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>{episode.title}</Link>
        </li>
      ))}

    </div>
  );
};

export default Episodes;
