import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getFirestore, setDoc, doc, getDoc, updateDoc, deleteDoc, addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { useNavigate } from 'react-router-dom';  // Import useHistory

import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Forms.css';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

function EpisodeDetails() {
  const [episode, setEpisode] = useState(null);
  const [editableEpisode, setEditableEpisode] = useState({});
  const [image, setImage] = useState(null);
  const [isSaved, setIsSaved] = useState(false); // New state to handle the display of the success message

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const episodeKey = params.get('episodeKey');
  const seriesKey = params.get('seriesKey');
  const seriesTitle = params.get('seriesTitle');
  const seasonKey = params.get('seasonKey');
  const seasonTitle = params.get('seasonTitle');
  const mode = params.get('mode') || 'edit'; // Edit mode by default

  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    if (mode === 'add') {
      // Initialize an empty episode object for Add mode
      setEpisode({});
      setEditableEpisode({});
    } else {
      // Fetch existing episode details for Edit mode
      fetchEpisodeDetails();
    }
  }, [episodeKey, mode]);

  useEffect(() => {
    fetchEpisodeDetails();
  }, [episodeKey]);

  useEffect(() => {
    setEditableEpisode(episode);
  }, [episode]);

  const fetchEpisodeDetails = async () => {
    if (!episodeKey) return;

    const episodeRef = doc(db, 'episodes', episodeKey);
    const episodeSnap = await getDoc(episodeRef);
    if (episodeSnap.exists) {
      setEpisode(episodeSnap.data());
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being changed is "duration" or "year", parse it as an integer
    const parsedValue = (name === 'duration' || name === 'year') ? parseInt(value, 10) : value;

    // Check if parsedValue is NaN (not a number). If it is, revert it back to the original value.
    setEditableEpisode(prevState => ({ ...prevState, [name]: isNaN(parsedValue) ? value : parsedValue }));
  };

  // Handler to update editableEpisode with the chosen file's name
  const handleFileChange = (event) => {
    // Check if a file is selected
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // Update the editableEpisode state with the file's name
      // Note: Browsers won't provide the full path for security, so both values will be the file name
      setEditableEpisode(prevState => ({
        ...prevState,
        localFileName: file.name,
      }));
    }
  };

  // New function to handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  // Modified function to handle episode details update
  const updateEpisodeDetails = async (e) => {
    e.preventDefault();

    setIsSaved(false); // Reset the saved flag before starting the update

    // Upload image to Firebase Storage if selected
    if (image) {
      const storageRef = ref(storage, `posters/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      // Wait for upload to complete and then get URL
      await new Promise((resolve, reject) => {
        uploadTask.on('state_changed', () => { }, reject, async () => {
          const downloadURL = await getDownloadURL(storageRef);
          editableEpisode.posterUrl = downloadURL;
          resolve();
        });
      });
    }

    if (mode === 'add') {
      // Generate a 40-character random key for the new episode
      const newEpisodeKey = generateRandomKey();

      // Add new episode to Firestore with the generated key as the document name
      const episodeRef = doc(db, 'episodes', newEpisodeKey);
      await setDoc(episodeRef, { ...editableEpisode, key: newEpisodeKey });

      // Redirect to the edit page for the newly created episode
      navigate(`/episodedetails?episodeKey=${newEpisodeKey}&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}&seasonKey=${seasonKey}&seasonTitle=${seasonTitle}`);
    } else {
      // Update existing episode
      const episodeRef = doc(db, 'episodes', episodeKey);
      await updateDoc(episodeRef, editableEpisode);
    }

    // Refresh the data
    fetchEpisodeDetails();

    setIsSaved(true); // Set the saved flag after successfully updating the Firestore data
  };

  // Helper function to generate a 40-character random key
  const generateRandomKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 40; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Function to handle button click
  const handleBackClick = () => {
    window.location.href = '/episodes';
  };

  // Function to handle delete click
  const handleDeleteClick = async () => {
    // Show a confirmation dialog to the user
    if (window.confirm('Are you sure you want to delete this episode?')) {
      // Delete the episode from Firestore
      const episodeRef = doc(db, 'episodes', episodeKey);
      await deleteDoc(episodeRef);

      // Redirect to the list page after deletion
      navigate('/list');
    }
  };

  return (

    <div className="form-container">
      {editableEpisode ? (
        <form onSubmit={updateEpisodeDetails}>
          <div className="breadcrumbs">
            /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
            /&nbsp; <Link to={`/series`}>Series</Link>&nbsp;
            /&nbsp; <Link to={`/seriesdetails?mode=edit&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>{seriesTitle && seriesTitle}</Link>&nbsp;
            /&nbsp; <Link to={`/seasons?seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>Seasons</Link>&nbsp;
            /&nbsp; <Link to={`/seasondetails?mode=edit&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}&seasonKey=${seasonKey}&seasonTitle=${seasonTitle}`}>{seasonTitle && seasonTitle}&nbsp;</Link>&nbsp;
            /&nbsp; <Link to={`/episodes?seriesKey=${seriesKey}&seriesTitle=${seriesTitle}&seasonKey=${seasonKey}&seasonTitle=${seasonTitle}`}>Episodes</Link>&nbsp;
            /&nbsp; {episode && episode.title}
          </div>
          <h1>
            {seriesTitle}&nbsp;-&nbsp;{seasonTitle}&nbsp;<br />
            <span className="form-title">{mode === 'add' ? 'Add New Episode' : 'Edit Episode Details'}</span>
          </h1>
          <button type="button" className="form-button" onClick={handleDeleteClick}>Delete Episode</button>

          <br />
          <br />

          {/* File Input */}
          <label className="form-label">
            Poster Image:
            {/* Show the poster image if it exists */}
            {editableEpisode.posterUrl && (
              <div className="poster-container">
                <img
                  src={editableEpisode.posterUrl}
                  alt="Episode Poster"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    height: 'auto',
                    width: '100%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: '160px'
                  }}
                  className='poster-image'
                />
              </div>
            )}

            <input type="file" id="fileInput" onChange={handleImageChange} className="form-input" />
            <label htmlFor="fileInput" className="fileInputLabel">Select Image</label>

          </label>

          <br />
          <br />

          {/* Input fields */}
          <label className="form-label">
            Title:
            <input type="text" name="title" value={editableEpisode.title || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            File Name:
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <button className="browse-button" onClick={() => document.getElementById('fileInput').click()}>
              Map to File
            </button>
            <input
              type="text"
              name="localFileName"
              value={editableEpisode.localFileName || ''}
              onChange={handleChange}  // Assuming you have an onChange handler named handleChange
              className="form-input"
            />
          </label>

          <label className="form-label">
            Year:
            <input type="text" name="year" value={editableEpisode.year || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Rating:
            <input type="text" name="rating" value={editableEpisode.rating || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Genres:
            <input type="text" name="genres" value={editableEpisode.genres || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Lessons:
            <input type="text" name="lessons" value={editableEpisode.lessons || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Director:
            <input type="text" name="director" value={editableEpisode.director || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Actors:
            <input type="text" name="actors" value={editableEpisode.actors || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Duration (Minutes):
            <input type="text" name="duration" value={editableEpisode.duration || ''} onChange={handleChange} className="form-input" />
          </label>

          {/* Textarea for Synopsis */}
          <label className="form-label">
            Synopsis:
            <textarea name="synopsis" value={editableEpisode.synopsis || ''} onChange={handleChange} className="form-textarea" />
          </label>

          {/* Update Button */}
          <div>
            <button type="submit" className="form-button">{mode === 'add' ? 'Add Episode' : 'Update Details'}</button>

            {/* Display success message conditionally */}
            {isSaved && (
              <span className="success-message">Changes saved.</span>
            )}

          </div>
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default EpisodeDetails;
