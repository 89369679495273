import React, { createContext, useContext } from 'react';

// Create a Context object
const UserContext = createContext();

// Create a hook to use this Context
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

// Create a Provider component to wrap your app
export const UserProvider = ({ children }) => {
  // Hard-coded user object
  const user = {
    key: '1234567890',
    name: 'Kevin',
  };

  console.log('UserProvider rendered!');

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
