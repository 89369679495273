import React, { useState, useEffect } from 'react';

import Splash from './Splash'; // Ensure correct path
import Browse from './Browse'; // Ensure correct path

const Home = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const loginDuration = 21600000; // 6 hours
    // 600000 = 10 minutes in milliseconds

    useEffect(() => {
        const loginTime = localStorage.getItem('loginTime');
        if (loginTime && new Date().getTime() - parseInt(loginTime) < loginDuration) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLogin = () => {
        localStorage.setItem('loginTime', new Date().getTime().toString());
        setIsLoggedIn(true);
        setTimeout(() => setIsLoggedIn(false), loginDuration); // Automatically logout after 10 minutes
    };

    return (
        <div>
            {isLoggedIn ? <Browse /> : <Splash onSuccessfulLogin={handleLogin} />}
        </div>
    );
};

export default Home;
