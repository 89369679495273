import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import firebaseApp from './firebaseConfig';
import './Forms.css';
import MovieList from './MovieList';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);

const Search = () => {
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMovies, setFilteredMovies] = useState([]); // State to hold filtered movies
  const searchInputRef = useRef(null);  // Create a ref

  useEffect(() => {
    // Focus the search input field when the component mounts
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      performSearch();
    }
  };

  // Perform the search and update filteredMovies state
  const performSearch = () => {
    setFilteredMovies([])
    
    // Define movie collection
    const moviesCollection = collection(db, 'movies');
    
    // Create query for title
    const titleQuery = query(
      moviesCollection,
      where('title', '>=', searchTerm),
      where('title', '<=', searchTerm + '\uf8ff')
    );
    
    // Create query for synopsis
    const synopsisQuery = query(
      moviesCollection,
      where('synopsis', '>=', searchTerm),
      where('synopsis', '<=', searchTerm + '\uf8ff')
    );

    // Fetch movies by title
    const fetchByTitle = onSnapshot(titleQuery, snapshot => {
      const movieList = snapshot.docs.map(doc => doc.data());
      setFilteredMovies(prevMovies => [...prevMovies, ...movieList]);
    });

    // Fetch movies by synopsis
    const fetchBySynopsis = onSnapshot(synopsisQuery, snapshot => {
      const movieList = snapshot.docs.map(doc => doc.data());
      setFilteredMovies(prevMovies => [...prevMovies, ...movieList]);
    });

    // You may want to unsubscribe these later in cleanup
  };

  return (
    <div className="movie-list-container">
      <div className="breadcrumbs">
        /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
        /&nbsp; Search Movies
      </div>

      {/* SEARCH FIELD */}
      <input 
        type="text"
        className="search-field"
        placeholder="Enter movie title..."
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        ref={searchInputRef}  // Attach the ref to the input element
      />

      <br /><br />

      {/* RESULTS */}
      {filteredMovies &&
        <MovieList movies={filteredMovies} />
      }
    </div>
  );
};

export default Search;
